<template>
  <div>
    <tiptap
      class="mb-5"
      label="Fabric Information"
      v-model="fields.fabric_information"
    />
    <tiptap
      class="mb-5"
      label="Available Colours"
      v-model="fields.available_colours"
    />
    <v-select
      label="Brand"
      v-model="fields.brand"
      :items="brands"
      item-text="name"
      item-value="id"
      outlined
      background-color="white"
    ></v-select>
    <v-select
      label="Tags"
      v-model="fields.tags"
      :items="tags"
      outlined
      background-color="white"
    ></v-select>
  </div>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },
  props: {
    product: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },

  data() {
    return {
      fields: {
        fabric_information: null,
        available_colours: null,
        tags: null,
        brand: null,
      },
      tags: ["Hats", "Gloves", "Scarves", "PPE Accessories"],
      errors: {},
    };
  },

  mounted() {
    if (this.product !== null) {
      this.fields.fabric_information = this.product.details.fabric_information;
      this.fields.available_colours = this.product.details.available_colours;
      this.fields.brand = this.product.details.brand;
      this.fields.tag = this.product.details.tag;
    }
  },

  computed: {
    brands() {
      return this.$store.state.eow.products["brands"];
    },
  },

  methods: {
    saveForm() {
      return this.fields;
    },

    setErrors(errors = {}) {
      this.errors = errors;
    },
  },
};
</script>
