<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Product</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="saveForm" method="post" id="product-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Slug *"
            v-model="slug"
            @change="onChange($event)"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>
          <v-text-field
            label="Subheading  *"
            v-model="fields.sub_title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('sub_title')"
            :error-messages="errors['sub_title']"
          ></v-text-field>
          <!--<v-textarea
            label="Short Description *"
            v-model="fields.short_description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
          ></v-textarea>-->
          <v-textarea
            label="Description *"
            v-model="fields.description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>

          <v-switch
            label="Can Purchase?"
            v-model="fields.can_purchase"
            inset
            :error="errors.hasOwnProperty('can_purchase')"
            :error-messages="errors['can_purchase']"
          ></v-switch>

          <v-text-field
            label="Max Purchase Quantity"
            v-model="fields.max_purchase_quantity"
            type="text"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('max_purchase_quantity')"
            :error-messages="errors['max_purchase_quantity']"
          ></v-text-field>
          <v-file-input
            v-model="fields.thumbnail"
            accept="image/png, image/jpeg"
            placeholder="Choose a Thumbnail Image"
            prepend-icon="mdi-camera"
            label="Thumbnail *"
            outlined
            background-color="white"
            @change="thumbnailHasChanged()"
            :error="errors.hasOwnProperty('thumbnail')"
            :error-messages="errors['thumbnail']"
          ></v-file-input>
          <v-select
            label="Categories"
            v-model="fields.categories"
            :items="categories"
            item-text="name"
            item-value="id"
            outlined
            multiple
            chips
            background-color="white"
            :error="errors.hasOwnProperty('categories')"
            :error-messages="errors['categories']"
          ></v-select>

          <PriceForm ref="priceForm" :product="product" />

          <DownloadForm ref="downloadForm" :product="product" />

          <v-text-field
            label="Display Order"
            v-model="fields.display_order"
            type="number"
            outlined
            dense
            background-color="white"
            :error="errors.hasOwnProperty('display_order')"
            :error-messages="errors['display_order']"
          ></v-text-field>
          <FileUpload
            ref="fileUpload"
            label='<b>Image Upload</b><br />Drag & Drop your images or <span class="filepond--label-action"> Browse </span>'
          />

          <!-- <v-select
            label="Product Type *"
            :items="types"
            item-value="name"
            item-text="label"
            v-model="fields.type"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>
          <GrassesDetailsForm
            ref="grassesDetails"
            :product="isEditing ? product : null"
            v-if="fields.type === 'grasses'"
          />
          <WildflowersDetailsForm
            ref="wildflowersDetails"
            :product="isEditing ? product : null"
            v-if="fields.type === 'wildflowers'"
          /> -->

          <WorkwearDetailsForm
            ref="workwearDetails"
            :product="isEditing ? product : null"
          />

          <!-- <FertilisersDetailsForm
            ref="fertilisersDetails"
            :product="isEditing ? product : null"
            v-if="fields.type === 'fertilisers'"
          />

          <SurfactantsDetailsForm
            ref="surfactantsDetails"
            :product="isEditing ? product : null"
            v-if="fields.type === 'surfactants'"
          />

          <PesticidesDetailsForm
            ref="pesticidesDetails"
            :product="isEditing ? product : null"
            v-if="fields.type === 'pesticides'"
          />

          <EquipmentDetailsForm
            ref="equipmentDetails"
            :product="isEditing ? product : null"
            v-if="fields.type === 'equipment'"
          /> -->
        </v-form>
        <v-overlay absolute v-if="productLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div v-if="errorMessage" class="red--text lighten-4">
          <v-icon small class="red--text mr-2">mdi-alert-circle</v-icon
          >{{ errorMessage }}
        </div>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          :disabled="productLoading"
          form="product-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "./FileUpload";
// import GrassesDetailsForm from "./GrassesDetailsForm";
// import FertilisersDetailsForm from "./FertilisersDetailsForm";
import WorkwearDetailsForm from "./WorkwearDetailsForm";
// import WildflowersDetailsForm from "./WildflowersDetailsForm";
// import PesticidesDetailsForm from "./PesticidesDetailsForm";
// import SurfactantsDetailsForm from "./SurfactantsDetailsForm";
// import EquipmentDetailsForm from "./EquipmentDetailsForm";
import PriceForm from "./PriceForm";
import DownloadForm from "./DownloadForm";

export default {
  components: {
    FileUpload,
    WorkwearDetailsForm,
    PriceForm,
    DownloadForm,
  },

  data() {
    return {
      media: {
        saved: [],
        added: [],
        removed: [],
      },
      dialog: false,
      loading: false,
      productLoading: false,
      isEditing: false,
      readers: [],
      product: {},

      fields: {
        images: [],
        downloads: [],
        name: null,
        slug: null,
        sub_title: null,
        short_description: null,
        description: null,
        display_order: null,
        categories: [],
        sizes: [],
        can_purchase: false,
        thumbnail: null,
        max_purchase_quantity: 0,
        thumbnail_has_changed: false,
        include_vat: false,
        benefits: null,
        type: null,
      },
      errors: {},
      errorMessage: null,
      // types: [
      //   { label: "Grasses", name: "grasses" },
      //   { label: "Wildflowers", name: "wildflowers" },
      //   { label: "Surfactants", name: "surfactants" },
      //   { label: "Fertilisers", name: "fertilisers" },
      //   { label: "Pesticides", name: "pesticides" },
      //   { label: "Equipment", name: "equipment" },
      //   { label: "Workwear", name: "workwear" },
      //   { label: "Other", name: "other" },
      // ],
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },

    categories() {
      return this.$store.getters["eow/productsStore/categories"];
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    onChange(event) {
      this.fields.slug = event;
    },
    openForm(productId = null) {
      if (this.$refs.fileUpload != undefined) {
        this.$refs.fileUpload.images = [];
        this.$refs.fileUpload.files = [];
        this.$refs.fileUpload.removedImages = [];
        this.$refs.fileUpload.addedImages = [];
      }

      if (productId !== null) {
        this.isEditing = true;
        this.productLoading = true;

        this.$store
          .dispatch("eow/productsStore/loadProduct", {
            appId: this.$route.params.id,
            productId,
          })
          .then((product) => {
            this.product = product;

            this.fields.name = product.name;
            this.fields.slug = product.slug;
            this.fields.display_order = product.display_order;
            this.fields.short_description = product.short_description;
            this.fields.description = product.description;

            this.fields.thumbnail = product.thumbnail
              ? new File([product.thumbnail], product.thumbnail)
              : null;
            this.fields.categories = product.categories.map((c) => c.id);

            this.$refs.priceForm.fields.prices = product.sizes;

            // product.downloads.forEach((values, keys) => {
            //   product.downloads[keys].changed = false;
            //   product.downloads[keys].file = new File(
            //     [values.file],
            //     values.file
            //   );
            // });

            this.$refs.downloadForm.fields.downloads = product.downloads;

            this.$refs.fileUpload.setImages(product.images, true);

            this.fields.include_vat = product.include_vat;
            this.fields.type = product.type;
            //  this.fields.max_purchase_quantity = product.max_purchase_quantity;
            this.fields.sub_title = product.sub_title;
            this.fields.can_purchase = product.can_purchase;

            this.productLoading = false;
          });
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.product = {};
      this.errors = {};
      this.fields.name = null;
      this.fields.slug = null;
      this.fields.sub_title = null;
      this.fields.short_description = null;
      this.fields.description = null;
      this.fields.categories = [];
      this.fields.images = [];

      this.fields.display_order = null;
      this.fields.thumbnail = null;
      this.fields.thumbnail_has_changed = false;
      this.fields.include_vat = false;
      this.fields.type = null;
      this.fields.max_purchase_quantity = 0;
      this.fields.sub_title = null;
      this.fields.can_purchase = false;
      this.$refs.fileUpload.addedImages = [];
      this.$refs.fileUpload.files = [];
      this.$refs.fileUpload.removedImages = [];
      this.fields.prices = [];
      this.$refs.priceForm.fields.prices = [];
      this.fields.downloads = [];
      this.$refs.downloadForm.fields.downloads = [];

      this.errorMessage = null;
      this.$refs.formWrapper.scrollTop = 0;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      this.fields.removedImages = this.$refs.fileUpload.getRemovedImages();
      this.fields.addedImages = this.$refs.fileUpload.getAddedImages();

      let fields = ((type) => {
        switch (type) {
          case "grasses":
            return this.$refs.grassesDetails.saveForm();
          case "wildflowers":
            return this.$refs.wildflowersDetails.saveForm();
          case "surfactants":
            return this.$refs.surfactantsDetails.saveForm();
          case "fertilisers":
            return this.$refs.fertilisersDetails.saveForm();
          case "pesticides":
            return this.$refs.pesticidesDetails.saveForm();
          case "equipment":
            return this.$refs.equipmentDetails.saveForm();
          case "workwear":
            return this.$refs.workwearDetails.saveForm();
          default:
            return null;
        }
      })(this.fields.type);

      this.fields.prices = this.$refs.priceForm.saveForm();
      this.fields.downloads = this.$refs.downloadForm.saveForm();

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: {
          ...fields,
          ...this.fields,
        },
      };

      if (this.isEditing) {
        payload.productId = this.product.id;
      }

      this.$store
        .dispatch("eow/productsStore/saveProduct", payload)
        .then(() => {
          this.resetForm();

          if (payload.fields.type === "wildflowers") {
            this.$refs.wildflowersDetails.setErrors();
          } else if (payload.fields.type === "grasses") {
            this.$refs.grassesDetails.setErrors();
          } else if (payload.fields.type === "surfactants") {
            this.$refs.surfactantsDetails.setErrors();
          } else if (payload.fields.type === "fertilisers") {
            this.$refs.fertilisersDetails.setErrors();
          } else if (payload.fields.type === "pesticides") {
            this.$refs.pesticidesDetails.setErrors();
          } else if (payload.fields.type === "equipment") {
            this.$refs.equipmentDetails.setErrors();
          } else if (payload.fields.type === "workwear") {
            this.$refs.workwearDetails.setErrors();
          }
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          if (payload.fields.type === "wildflowers") {
            this.$refs.wildflowersDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "grasses") {
            this.$refs.grassesDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "surfactants") {
            this.$refs.surfactantsDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "fertilisers") {
            this.$refs.fertilisersDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "pesticides") {
            this.$refs.pesticidesDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "equipment") {
            this.$refs.equipmentDetails.setErrors(err.response.data.errors);
          } else if (payload.fields.type === "workwear") {
            this.$refs.workwearDetails.setErrors(err.response.data.errors);
          }

          this.loading = false;

          if (this.errors) {
            this.errorMessage =
              "Please ensure all fields are completed correctly";

            this.$refs.formWrapper.scrollTop = 0;
          }
        });
    },

    thumbnailHasChanged() {
      this.fields.thumbnail_has_changed = true;
    },

    seedIllustrationHasChanged() {
      this.fields.seed_illustration_has_changed = true;
    },
  },
};
</script>
