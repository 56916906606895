<template>
  <file-pond
    name="file"
    :server="server"
    accepted-file-types="image/jpeg, image/png"
    credits="false"
    v-bind:allow-multiple="true"
    :labelIdle="label"
    maxFiles="10"
    allowReorder="true"
    :files="files"
    :onaddfile="onAddImage"
    :onremovefile="onRemoveImage"
    allowRevert="false"
  />
</template>

<script>
import { getApiUrl } from "@/utils";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  components: {
    FilePond,
  },

  productId: {
    type: Number,
    required: true,
  },

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Drag & Drop your files or <span class="filepond--label-action"> Browse </span>',
    },
  },

  data() {
    return {
      files: [],
      images: [],
      addedImages: [],
      removedImages: [],
      file: null,
      isEditing: false,
      hasChanged: false,
      hasDeleted: false,
    };
  },

  mounted() {},

  computed: {
    server() {
      const appId = this.$route.params.id;
      const token = localStorage.getItem("token");

      return {
        url: `${getApiUrl(appId)}/module/eow/products/file`,
        process: null,
        revert: null,
        remove: null,
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    },
  },

  methods: {
    setImages(images) {
      for (var i = 0; i < images.length; i++) {
        this.files.push({
          source: images[i].image_url,
          options: {
            type: "local",
          },
        });
      }
    },

    clearImages() {
      this.images = [];
      this.file = null;
      this.files = [];
      this.addedImages = [];
      this.removedImages = [];

      this.files.push({
        source: [],
        options: {
          type: "local",
        },
      });
    },

    getFile() {
      return this.file;
    },
    getImages() {
      return this.files;
    },

    getRemovedImages() {
      return this.removedImages;
    },

    getAddedImages() {
      return this.addedImages;
    },

    onAddImage(error, file) {
      if (error) {
        return;
      }
      this.file = file.file;

      if (this.isFile(this.file)) {
        this.addedImages.push(this.file);
      } else {
        this.files.push(this.file);
      }
    },

    isFile(input) {
      if ("File" in window && input instanceof File) return true;
      else return false;
    },

    onRemoveImage(error, file) {
      if (error) {
        return;
      }
      this.files.filter((item) => item !== this.file);

      this.removedImages.push(file.source);
    },
  },
};
</script>
