<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-eow-products' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Products</v-btn
        >
      </v-row>
      <v-row no-gutters align="center" class="mt-0">
        <h1>{{ product.name }}</h1>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title> Basic Information</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.productDialog.open(product)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Product</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ product.name }}</td>
                </tr>
                <tr>
                  <th>Sub title</th>
                  <td>{{ product.sub_title }}</td>
                </tr>

                <tr>
                  <th>Short description</th>
                  <td>{{ product.short_description }}</td>
                </tr>

                <tr>
                  <th>description</th>
                  <td><div v-html="product.description"></div></td>
                </tr>

                <tr>
                  <th>Thumbnail</th>
                  <td>
                    <v-img
                      v-if="product.thumbnail"
                      height="100"
                      :src="product.asset_urls.thumbnail"
                      aspect-ratio="1"
                      cover
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                    >
                    </v-img>
                  </td>
                </tr>

                <tr>
                  <th>Price</th>
                  <td>{{ product.price }}</td>
                </tr>

                <tr>
                  <th>Is out of stock</th>
                  <td>{{ product.is_out_of_stock }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <product-dialog ref="productDialog" />
  </div>
</template>

<script>
import ProductDialog from "./components/ProductForm.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {
    ProductDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Products",
          disabled: false,
          to: { name: "module-eow-products" },
          exact: true,
        },
      ],
      searchTerm: "",
      fieldsMessage: {
        message: null,
      },
      fieldsSale: {
        early_access: false,
        general_sale: false,
      },
      loading: false,
      loadingSale: false,
      errors: {},
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
    };
  },

  mounted: function () {},

  computed: {
    product() {
      return this.$store.getters["eow/productsStore/get"];
    },

    tableHeaders() {
      return [
        { text: "Customer", value: "customer" },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },

  // created() {
  //   this.breadcrumbs.push({
  //     text: this.product.name,
  //     disabled: true,
  //   });
  // },

  methods: {
    saveFormSale() {
      this.loadingSale = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fieldsSale,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("eow/productsStore/saveProductSale", payload)
        .then(() => {
          this.resetFormSale();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loadingSale = false;
        });
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        productId: this.$route.params.productId,
        fields: this.fieldsMessage,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("eow/productsStore/saveProductEmail", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
    },

    resetFormSale: function () {
      this.loadingSale = false;
      this.isEditing = true;
    },
  },
};
</script>
