<template>
  <v-card outlined>
    <v-card-subtitle v-html="label" v-if="label"></v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <div v-if="editor" class="mb-4">
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('bold') ? 'black' : ''"
          :outlined="editor.isActive('bold')"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('italic') ? 'black' : ''"
          :outlined="editor.isActive('italic')"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('paragraph') ? 'black' : ''"
          :outlined="editor.isActive('paragraph')"
          @click="editor.chain().focus().setParagraph().run()"
        >
          <v-icon>mdi-format-paragraph</v-icon>
        </v-btn>

        <v-btn
          depressed
          icon
          tile
          @click="setLink"
          :class="{ 'is-active': editor.isActive('link') }"
        >
          <v-icon>mdi-link</v-icon>
        </v-btn>

        <v-btn
          depressed
          icon
          tile
          @click="editor.chain().focus().unsetLink().run()"
          :disabled="!editor.isActive('link')"
        >
          <v-icon>mdi-link-off</v-icon>
        </v-btn>

        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('heading', { level: 1 }) ? 'black' : ''"
          :outlined="editor.isActive('heading', { level: 1 })"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          <v-icon>mdi-format-header-1</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('heading', { level: 2 }) ? 'black' : ''"
          :outlined="editor.isActive('heading', { level: 2 })"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          <v-icon>mdi-format-header-2</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('heading', { level: 3 }) ? 'black' : ''"
          :outlined="editor.isActive('heading', { level: 3 })"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          <v-icon>mdi-format-header-3</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('heading', { level: 4 }) ? 'black' : ''"
          :outlined="editor.isActive('heading', { level: 4 })"
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        >
          <v-icon>mdi-format-header-4</v-icon>
        </v-btn>
        <!-- <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('heading', { level: 5 }) ? 'black' : ''"
          :outlined="editor.isActive('heading', { level: 5 })"
          @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        >
          <v-icon>mdi-format-header-5</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('heading', { level: 6 }) ? 'black' : ''"
          :outlined="editor.isActive('heading', { level: 6 })"
          @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        >
          <v-icon>mdi-format-header-6</v-icon>
        </v-btn> -->
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('bulletList') ? 'black' : ''"
          :outlined="editor.isActive('bulletList')"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('orderedList') ? 'black' : ''"
          :outlined="editor.isActive('orderedList')"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <v-icon>mdi-format-list-numbered</v-icon>
        </v-btn>
        <!-- <v-btn
          depressed
          icon
          tile
          :color="editor.isActive('blockquote') ? 'black' : ''"
          :outlined="editor.isActive('blockquote')"
          @click="editor.chain().focus().toggleBlockquote().run()"
        >
          <v-icon>mdi-format-quote-close</v-icon>
        </v-btn> -->
        <v-btn depressed icon tile @click="editor.chain().focus().undo().run()">
          <v-icon>mdi-undo</v-icon>
        </v-btn>
        <v-btn depressed icon tile @click="editor.chain().focus().redo().run()">
          <v-icon>mdi-redo</v-icon>
        </v-btn>
      </div>
      <editor-content :editor="editor" v-model="value" />

      <div v-if="localError">
        <p v-for="(error, index) in localError" :key="index" class="error-text">
          {{ error }}
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },

    maxLength: {
      type: Number,
      default: 200, // Default validation rule
    },

    laravelError: Array,
  },

  data() {
    return {
      editor: null,
      localError: "",
    };
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: false,
        }),
      ],
      onUpdate: () => {
        this.html = this.editor.getHTML();
        if (this.html === "<p></p>") this.html = "";
        this.$emit("input", this.html);

        this.validateContent();
      },
    });
  },

  watch: {
    value() {
      let html = this.editor.getHTML();
      if (html !== this.value) {
        this.editor.commands.setContent(this.value);
      }
    },
    laravelError(newError) {
      this.localError = newError;
    },
  },

  methods: {
    validateContent() {
      this.$emit("input", this.editor.getHTML()); // Emit updated content
    },

    setLink() {
      const url = window.prompt("URL");

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.error-text {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  padding: 0.75em;
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  min-height: 150px;
  max-height: 300px;
  overflow-y: scroll;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  a {
    color: #68cef8;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    // font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
}
</style>
